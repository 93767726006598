import { default as React } from "react"
import VisibilitySensor from "react-visibility-sensor"
import anime from "animejs/lib/anime.es.js"

const AnimateOnSeen = ({ animeConfig, children }) => {
  const containerRef = React.useRef(null)
  const [seen, setSeen] = React.useState(false)

  return (
    <VisibilitySensor
      partialVisibility
      delayedCall
      onChange={isVisible => {
        if (isVisible && !seen) {
          setSeen(true)

          anime({
            ...animeConfig,
            targets: animeConfig.targets || containerRef.current,
          })
        }
      }}
    >
      <div ref={containerRef}>{children}</div>
    </VisibilitySensor>
  )
}

export default AnimateOnSeen
