import MobileStepper from "@material-ui/core/MobileStepper"
import { withStyles } from "@material-ui/core/styles"
import { colors } from "../theme"

export default withStyles(theme => {
  return {
    root: {
      backgroundColor: "inherit",
    },
    dotActive: {
      backgroundColor: colors.link,
    },
  }
})(MobileStepper)
