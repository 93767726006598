import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import axios from "axios"
import { Field, Form, Formik } from "formik"
import { CheckboxWithLabel, TextField } from "formik-material-ui"
import { Link as GatsbyLink, navigate } from "gatsby"
import { useSnackbar } from "notistack"
import React from "react"
import * as Yup from "yup"
import BigForm from "../components/big-form"
import CTAButton from "../components/CTAButton"
import PhoneMaskedInput from "../components/phone-masked-input"
import { Statistics } from "../components/statistics"
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PHONE_NUMBER_REGEX,
} from "../constants"
import LogoFullWhite from "../img/logo-full-white.svg"
import DialogTitle from "./dialog-title"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const PASSWORD_VALIDATION_MESSAGE = `Must contain ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters and at least 1 letter`
const SignupShipperSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .matches(/[^\d]/, PASSWORD_VALIDATION_MESSAGE)
    .min(PASSWORD_MIN_LENGTH, PASSWORD_VALIDATION_MESSAGE)
    .max(PASSWORD_MAX_LENGTH, PASSWORD_VALIDATION_MESSAGE)
    .required("Required"),
  phone: Yup.string()
    .matches(PHONE_NUMBER_REGEX, "Invalid phone number. Please verify.")
    .required("Required"),
  companyName: Yup.string().required("Required"),
  companyType: Yup.string().required("Required"),
  terms: Yup.boolean().oneOf([true], "Required"),
})

const createShipper = signupShipperValues => {
  const {
    companyName,
    companyType,
    email,
    password,
    firstName,
    lastName,
    phone,
  } = signupShipperValues

  const data = {
    email,
    name: companyName,
    phoneNumber: phone,
    companyTypeId: "YK41K", // Hardcoded value for shipper type
    companySubtype: companyType,
    owner: {
      name: `${firstName} ${lastName}`,
      password,
      email,
      phoneNumber: phone,
    },
  }

  return axios.post("/api/shipperlite/user/v1/companies/register", data)
}

const useStyles = makeStyles({
  dialogContent: {
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
})

export const SignUpBrokerTemplate = ({ data: { title }, statisticsData }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [interruptDialogOpen, setInterruptDialogOpen] = React.useState(false)

  const handleInterruptDialogClose = () => {
    setInterruptDialogOpen(false)
  }

  return (
    <BigForm
      title={title}
      subtitle={
        <Typography variant="subtitle2" align="center" color="textSecondary">
          <span>Not a Shipper?</span>{" "}
          <b>
            <Link component={AdapterLink} to="/sign-up-carrier">
              Click here
            </Link>
          </b>{" "}
          <span>for Carrier sign up.</span>
        </Typography>
      }
      form={
        <div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              password: "",
              companyName: "",
              companyType: "",
              terms: false,
            }}
            validationSchema={SignupShipperSchema}
            onSubmit={(values, { setSubmitting }) => {
              createShipper(values)
                .then(() => {
                  navigate("/sign-up-carrier-welcome", {
                    state: { formValues: values },
                  })
                })
                .catch(error => {
                  console.dir(error)
                  setSubmitting(false)

                  if (error.response && error.response.data) {
                    if (
                      error.response.data.errorDetails &&
                      Array.isArray(error.response.data.errorDetails)
                    ) {
                      enqueueSnackbar(error.response.data.errorDetails[0], {
                        variant: "error",
                      })
                    } else {
                      enqueueSnackbar(error.response.data.message, {
                        variant: "error",
                      })
                    }
                  }
                })
            }}
          >
            {({ values, touched, submitForm, isSubmitting, errors }) => {
              return (
                <div>
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="firstName"
                          label="First Name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="lastName"
                          label="Last Name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="email"
                          type="email"
                          label="Email"
                          fullWidth
                          helperText="Will serve as login"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          type="password"
                          label="Password"
                          name="password"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="phone"
                          label="Phone"
                          fullWidth
                          InputProps={{
                            inputComponent: PhoneMaskedInput,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="companyName"
                          label="Company Name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="companyType"
                          label="Company Type"
                          select
                          fullWidth
                        >
                          <MenuItem value="dealer">Dealer</MenuItem>
                          <MenuItem value="auction">Auction</MenuItem>
                          <MenuItem value="broker">Broker</MenuItem>
                          <MenuItem value="carrier">Carrier</MenuItem>
                          <MenuItem value="private">Private</MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          color="primary"
                          component={CheckboxWithLabel}
                          name="terms"
                          type="checkbox"
                          Label={{
                            label: (
                              <Typography variant="body2">
                                <span>I agree to Ship.Cars</span>{" "}
                                <strong>
                                  <Link
                                    target="_blank"
                                    href="/legal/loadmate-terms-of-use"
                                  >
                                    Terms of Use
                                  </Link>{" "}
                                </strong>
                                <span>and</span>{" "}
                                <strong>
                                  <Link
                                    target="_blank"
                                    href="/legal/privacy-policy"
                                  >
                                    Privacy Policy
                                  </Link>
                                </strong>
                                {errors.terms === "Required" && touched.terms && (
                                  <Box
                                    component="span"
                                    ml={1}
                                    color="error.main"
                                    whiteSpace="nowrap"
                                  >
                                    <FontAwesomeIcon
                                      icon={faExclamationTriangle}
                                    />{" "}
                                    <span>Required</span>
                                  </Box>
                                )}
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CTAButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="large"
                          disabled={isSubmitting}
                          onClick={() => {
                            if (
                              values.companyType === "carrier" ||
                              values.companyType === "private"
                            ) {
                              setInterruptDialogOpen(true)
                            } else {
                              submitForm()
                            }
                          }}
                        >
                          Create Account
                        </CTAButton>
                      </Grid>
                    </Grid>
                  </Form>
                  <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={interruptDialogOpen}
                    onClose={handleInterruptDialogClose}
                  >
                    <DialogTitle onClose={handleInterruptDialogClose} />
                    <DialogContent className={classes.dialogContent}>
                      {values.companyType === "carrier" && (
                        <Typography
                          variant="subtitle2"
                          align="center"
                          gutterBottom
                        >
                          <span>For Carrier sign up</span>{" "}
                          <b>
                            <Link
                              component={AdapterLink}
                              to="/sign-up-carrier"
                              noWrap
                            >
                              click here
                            </Link>
                          </b>
                        </Typography>
                      )}
                      {values.companyType === "private" && (
                        <Typography variant="subtitle2" gutterBottom>
                          This software is intended for companies only at this
                          time. Private consumers are not currently allowed on
                          LoadMate.
                        </Typography>
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              )
            }}
          </Formik>
        </div>
      }
      info={
        statisticsData && (
          <div>
            <Box mb={6} display="flex" justifyContent="center">
              <img height={38} src={LogoFullWhite} alt="Ship.Cars logo" />
            </Box>
            <Statistics
              description="Leading Changes, Building Connections"
              data={statisticsData}
              collapsed
              inheritColor
            />
          </div>
        )
      }
    />
  )
}

export default SignUpBrokerTemplate
